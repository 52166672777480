import { Box } from '@mui/material';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import { DidNameProvider } from './contexts/didNameContext';

const Inscription = lazy(() => import('./pages/Inscription'));
const Holders = lazy(() => import('./pages/Holders'));
const Market = lazy(() => import('./pages/Market'));

function App() {
  return (
    <DidNameProvider>
      <Router>
        <Header />
        <Suspense fallback={<Box textAlign={'center'} padding={4}>Loading...</Box>}>
          <Routes>
            <Route path="/" element={<Inscription />} />
            <Route path="/inscription" element={<Inscription />} />
            <Route path="/market" element={<Market />} />
            <Route path="/holders" element={<Holders />} />
          </Routes>
        </Suspense>
      </Router>
    </DidNameProvider>
  );
}

export default App;
