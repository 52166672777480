import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import { getInitialConfig, WalletProvider } from '@terra-money/wallet-kit';
import TerraStationMobileWallet from '@terra-money/terra-station-mobile';
// import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

getInitialConfig().then((defaultNetworks) => {
  ReactDOM.render(
    <WalletProvider defaultNetworks={defaultNetworks} extraWallets={[new TerraStationMobileWallet()]}>
      <App />
    </WalletProvider>,
    document.getElementById('root'),
  );
});