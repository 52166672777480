import React from 'react';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import LOGO from './logo.png';
import './header.scss';
import Connect from './Connect';
import { useConnectedWallet } from '@terra-money/wallet-kit';
import Badge from '@mui/material/Badge';

export default function Header() {
	let { pathname } = useLocation();
	
	return (
		<Box className="header-wrapper">
			<Box display={'flex'} alignItems="center">
				<Link className="logo" to="/inscription">
					<img src={LOGO} width="65px" height="53px" alt="logo" />
				</Link>
				<Link
					className={`link ${pathname.includes('/inscription') && 'selected'}`}
					to="/inscription"
				>
					Inscription
				</Link>
				<Link
					className={`link ${pathname.includes('/market') && 'selected'}`}
					to="/market"
				>
					Market
				</Link>
				

				<Link
					className={`link ${pathname.includes('/holders') && 'selected'}`}
					to="/holders"
				>
					Holders
				</Link>
				
				<Link target="_blank"
					className={`link ${pathname.includes('/dex') && 'selected'}`}
					to="https://www.dfswap.io/swap?type=0&offer=uluna&ask=terra13sagkcsdf039xfr4cthfv78f07ja8ey2eazktmktwqkve48ry3pqw79pzg"
				>
					Swap
				</Link>
			</Box>
			<Box display={'flex'} alignItems="center">				
				<Connect />
			</Box>
		</Box>
	);
}
