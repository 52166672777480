import React, { useEffect, useState, useCallback } from 'react'
import { useWallet, useConnectedWallet } from '@terra-money/wallet-kit';
import { Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { useNavigate } from 'react-router-dom';
import { useDidName } from '../../contexts/didNameContext';

const linkStyles = {
    color: '#000', 
    textDecoration: 'none', 
    display: 'flex', 
    alignItems: 'center',
    // marginTop: '20px',
}

export default function BtnMenu() {
    const connectedWallet = useConnectedWallet();
    const { didName } = useDidName()
    const { disconnect } = useWallet();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [isMobile, setIsMobile] = useState(false);
    
    const chainID = 'columbus-5';
    const mainnetChainId = 'phoenix-1';
    const terraAddress = connectedWallet?.addresses[chainID] === undefined ? connectedWallet?.addresses[mainnetChainId] : connectedWallet?.addresses[chainID];

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)'); // 定义媒体查询

        if (mediaQuery.matches) {
            setIsMobile(true); // 如果当前设备屏幕宽度小于等于767px，则为移动设备
        }

        const handleResize = () => {
            if (mediaQuery.matches) {
                setIsMobile(true); // 如果屏幕宽度小于等于767px，则为移动设备
            } else {
                setIsMobile(false); // 否则为桌面设备
            }
        };

        window.addEventListener('resize', handleResize); // 监听窗口大小变化事件

        return () => {
            window.removeEventListener('resize', handleResize); // 组件卸载时取消事件监听
        };
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDisconnect = () => {
        disconnect()
    }

    function processAddr(walletAdd) {
        return (isMobile ? '..' : '...') + walletAdd.slice(isMobile ? -3 : -6)
    }
    let walletAdd = (connectedWallet && terraAddress) || ''

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{textTransform: 'none', color: "#fff", border: '1px solid #fff', borderRadius: '20px 20px', paddingLeft: '20px', paddingRight: '20px'}}
            >   
                <AccountBalanceWalletIcon sx={{marginRight: '5px', fontSize: '16px'}} />
                {processAddr(walletAdd)}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        bgcolor: '#000',
                        border: '1px solid rgba(255,255,255,0.15)'
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{marginTop: "10px"}}
            >
                
                    <Tooltip title="View on Terra Finder" placement="top" arrow followCursor PopperProps={{
                        sx: {
                            "& .MuiTooltip-tooltip": {
                                background: '#fff',
                                color: "#000"
                              },
                            "& .MuiTooltip-arrow": {
                                "&:before": {
                                    color: '#fff'
                                  }
                              }
                        }
                    }}>
                        <a href={`https://finder.terraclassic.community/mainnet/address/${walletAdd}`} target="_blank" rel="noreferrer" style={linkStyles}>
                            <MenuItem sx={{
                                bgcolor: '#000',
                                color: '#fff',
                                ':hover': {
                                    color: '#ff4e19',
                                    bgcolor: '#000'
                                }
                            }}>
                                { processAddr(walletAdd) }
                                <CallMadeIcon fontSize='18px' />
                            </MenuItem>
                        </a>
                    </Tooltip>
                
                <MenuItem  sx={{
                                bgcolor: '#000',
                                color: '#fff',
                                ':hover': {
                                    color: '#ff4e19',
                                    bgcolor: '#000'
                                }
                            }} onClick={handleDisconnect}>Disconnect</MenuItem>
            </Menu>
        </Box>
    )
}