import React, { useEffect, useState } from 'react'
import { Snackbar, Alert as MuiAlert } from '@mui/material'
import { useWallet } from '@terra-money/wallet-kit';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CheckChainType() {
    const { network } = useWallet();
    const [open, setOpen] = useState(false)
    const chainID = 'columbus-5';
    useEffect(() => {
        if (network && network[chainID].name !== 'Terra Classic') {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [network])
    
    return (
        <Snackbar open={open}>
            <Alert severity="warning" sx={{ width: '100%' }}>
                You are now on { network.name } chain, please change to classic chain and connect again
            </Alert>
        </Snackbar>
    )
}